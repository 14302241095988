import { motion, useTransform, useScroll  } from "framer-motion";
import { useRef, useState, useEffect } from "react";
import ProjectDetails from './Details';

const Projects = ({ projects }) => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
  });
  const box_width = (window.innerWidth <= 680) ? window.innerWidth : 500;

  const x = useTransform(
    scrollYProgress,
    [0, 1],
    ["1%", `-${(box_width * projects.length - window.innerWidth)}px`]
  );
  const [currentProject, setCurrentProject] = useState(false);
  const [translation, setTranslation] = useState(0);  

  const goToProject = (key) => {
    const projectsElement = document.getElementById("projects");
    const projectsPosition = projectsElement.offsetTop;
    if(((key+1) === currentProject) || key === false){
      window.history.pushState('home', 'Ari - Portfolio', '/');
      setCurrentProject(false);
      setTranslation(0);
      document.body.style.overflow = 'initial';
    } else {
      let _key = key+1;
      setCurrentProject(_key);
      console.log(projects[_key].project_id);
      window.history.pushState('home', projects[key].title+' | Ari - Portfolio', '/p/'+projects[key].project_id);
      setTranslation(-key * box_width);
      const windowScrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (windowScrollTop < projectsPosition) {
        window.scrollTo({ top: projectsPosition, behavior: 'smooth' });
      }
      setTimeout(()=> {
        document.body.style.overflow = 'hidden';
      }, 1000);
    }
  }

  useEffect(() => {
    const path = window.location.pathname;
    if (path.includes("/p/")) {
      const p_id = path.replace('/p/', '');
      projects = projects.map((obj, index) => ({ ...obj, key: index }));
      const k_projects = projects.filter((project) => {
        return project.project_id === p_id;
      })
      if(k_projects && k_projects.length) goToProject(k_projects[0].key);
    }
  }, []);

  return (
    <>
      {
        (projects.length && currentProject) && (
        <div id="project-details">
          <div className="btn-close" onClick={ () => goToProject(false)} />
          <ProjectDetails project={projects[currentProject-1]} />
        </div>
        )
      }
      <div ref={targetRef} id="projects">
        <div className="projects-sticky">
          <motion.div style={{ x : (translation === 0) ? x : translation }} className={`projects-wrapper ${(translation === 0) ? '' : 'animated'}`}>
            {
              projects.map((project, key) => {
                return (
                  <div className={`project ${(currentProject===(key+1))?'active':''}`} key={key} onClick={ () => goToProject(key) }>
                    <div className="title">
                      <h3>{ project.category }</h3>
                      <h2 dangerouslySetInnerHTML={{ __html: project.title }} />
                    </div>
                    <div style={{ backgroundImage: `url(${project.banner})`, backgroundColor: `${project.color}` }} className="project-image" />
                  </div>
                )
              })
            }
          </motion.div>
        </div>
      </div>
    </>
  );
}

export default Projects;