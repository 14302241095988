import React from 'react';

const Clients = ({ children }) => {

  const clients = [
    { id: 'demensen', title: 'De Mensen' },
    { id: 'een', title: 'Eén' },
    { id: 'galloptaxshelter', title: 'Gallop Tax Shelter' },
    { id: 'gardneranddomm', title: 'Gardner & Domm' },
    { id: 'hlb', title: 'Het Laatste Bedrijf' },
    { id: 'humans', title: 'Humans' },
    { id: 'klara', title: 'Klara' },
    { id: 'lesgens', title: 'Les Gens' },
    { id: 'medialaan', title: 'Medialaan' },
    { id: 'mnm', title: 'MNM' },
    { id: 'philipsbiscuits', title: 'Philips Biscuits' },
    { id: 'radio1', title: 'Radio 1' },
    { id: 'radio2', title: 'Radio 2' },
    { id: 'stubru', title: 'StuBru' },
    { id: 'supastar', title: 'Supastar' },
    { id: 'vta', title: 'Vlaamse Televisie Academie' },
    { id: 'redrobot', title: 'Red Robot' },
    { id: 'makasi', title: 'Makasi' },
    { id: 'inu', title: 'INU' },
    { id: 'talenco', title: 'Talenco' },
    { id: 'jorissen', title: 'Jorissen & De Jonck' },
    { id: 'red', title: 'RED' },
    { id: 'thebeach', title: 'The Beach' },
    { id: 'localotg', title: 'LocalOTG' },
    { id: 'suvanto', title: 'Suvanto' },
    { id: 'raverepublic', title: 'Rave Republic' },
  ];

  return(
    <div id="clients">
      <div className="content">
        <h1>Clients</h1>
        <h2>Some amazing clients<br /><i>that I worked with</i></h2>
        <div className="clients-list">
          {
            clients.sort(() => Math.random() - 0.5).slice(0,24).map((value, key) => {
              return <div key={key} className={`client ${value.id}`} title={value.title} style={{ backgroundImage: `url(/files/clients/client-${value.id}.svg)` }} />
            })
          }
        </div>
      </div>
    </div>
  )
}

export default Clients;