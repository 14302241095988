

export const lang = {
	nl: {
		about: 'With more than 10 years of experience, I am an award-winning creative in the Entertainment Industry.\nWorked on digital experiences for some amazing companies and as a Creative director for some great artists.\n\nThroughout the years, I\'ve cultivated a diverse skill set, with a primary focus on frontend development. \nI\'m currently open for new opportunities, so please don\'t hesitate to connect for more information.',
	},
	en: {
		about: 'Hello Wereld',
	},
	fr: {
		about: 'Bonjour tout le monde',
	}
};