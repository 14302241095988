import React from 'react';
import logo from '@assets/images/logo.svg';
import Lottie from 'react-lottie';
import * as animationData from '@data/lottie/logo.json'


const Home = ({ children }) => {
  const defaultOptions = {
    loop: false,
    autoplay: true, 
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return(
    <div id="home">
      <div className="logo">
        <Lottie options={defaultOptions}
          height={400}
          width={400}
          isStopped={false}
          isPaused={false}/>
      </div>
      <div className="logo-mobile">
        <Lottie options={defaultOptions}
          height={250}
          width={250}
          isStopped={false}
          isPaused={false}/>
      </div>

    </div>
  )
}

export default Home;