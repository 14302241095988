import React, { useEffect, useRef } from 'react';

import { lang } from '@data/locales';

const About = ({ children }) => {
  const frame1Ref = useRef(null);
  const frame2Ref = useRef(null);
  const frame3Ref = useRef(null);

  const handleScroll = () => {
    const windowScrollY = window.scrollY;
    const speed1 = 0.05;
    const speed2 = 0.15;
    const speed3 = 0.1;
    frame1Ref.current.style.transform = `translateY(-${windowScrollY * speed1}px)`;
    frame2Ref.current.style.transform = `translateY(-${windowScrollY * speed2}px)`;
    frame3Ref.current.style.transform = `translateY(-${windowScrollY * speed3}px)`;
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div id="about" className="content">
      <div className="biography">
        <h1>About me</h1>
        <h2>Aäron <i>A.K.A. Ari</i></h2>
        <span className="description">{lang['nl'].about}</span>
        <span className="description">
          <h3>Development</h3>
          <p>React, Reacht Native, PHP, Firebase, Webflow, Strapi, CakePHP, MySQL, NoSQL, Git, Angular, Typescript …
          </p>
          <h3>Design</h3>
          <p>Figma, Photoshop, Illustrator, Sketch, Lightroom, UI, UX,  …</p>
          <h3>Hobbies</h3>
          <p>Photography, Drone photography, Music, Video, 3D,  …</p>
        </span>
      </div>
      <div className="wall">
        <div className="left">
          <div className="frame frame-1" ref={frame1Ref} />
        </div>
        <div className="right">
          <div className="frame frame-2" ref={frame2Ref} />
          <div className="frame frame-3" ref={frame3Ref} />
        </div>
      </div>
    </div>
  );
};

export default About;