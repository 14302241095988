import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyB0huDNVSfvOmqucVKbUzID6mwbPT7Z7G8",
  authDomain: "ari-portfolio-website.firebaseapp.com",
  projectId: "ari-portfolio-website",
  storageBucket: "ari-portfolio-website.appspot.com",
  messagingSenderId: "720680567609",
  appId: "1:720680567609:web:cae3797ede2f3ef5861ee8",
  measurementId: "G-8ZVFWCT7LS"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };