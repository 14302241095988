import React from 'react';


const Contact = ({ children }) => {
  return(
    <div id="contact">
      <div id="marker" />
      <div className="content">
        <h1>Contact</h1>
        <h2>From Belgium<br /><i>And currently living in Thailand</i></h2>
        <span className="description">
          Available for work, feel free to reach out to discuss your project.
        </span>
        <a href="mailto:depauw.aaron@gmail.com" target="_blank" rel="noreferrer" className="ico ico-mail">depauw.aaron@gmail.com</a> <br />
        <a href="https://www.linkedin.com/in/aarondepauw/" target="_blank" rel="noreferrer" className="ico ico-linkedin">/in/aarondepauw</a>
      </div>
    </div>
  )
}

export default Contact;