import { db } from "../";
import { collection, getDocs, doc, getDoc, query, orderBy, where } from "firebase/firestore";
//import { collection, query, where, getDocs, doc, addDoc, onSnapshot, orderBy, getDoc } from "firebase/firestore";

export const getDocument =  async (coll, id, callback)  =>  {
    const docRef = doc(db, coll, id);
    const docSnap = await getDoc(docRef);
    if(docSnap.exists()) {
        callback(docSnap.data());
    } else {
        callback("Document does not exist")
    }
};

export const getCollection = async (coll, callback) => {
    const q = collection(db, coll);
    const querySnapshot = await getDocs(q);
    let data = [];
    querySnapshot.forEach((doc) => {
        let _data = doc.data();
        _data.id = doc.id;
        data.push(_data);
    });
    callback(data);
}

export const getCollectionQuery = async (coll, callback, filters = [], sorting = []) => {
  const q = collection(db, coll);
  let queryRef = q;
  filters.forEach((filter) => {
    const { field, operator, value } = filter;
    queryRef = query(queryRef, where(field, operator, value));
  });
  sorting.forEach((sort) => {
    const { field, direction } = sort;
    queryRef = query(queryRef, orderBy(field, direction));
  });
  const querySnapshot = await getDocs(queryRef);
  let data = [];
  querySnapshot.forEach((doc) => {
    let _data = doc.data();
    _data.id = doc.id;
    data.push(_data);
  });
  callback(data);
};