import React, { useState } from 'react';


const ProjectDetails = ({ project }) => {
  const [currentPage, setCurrentPage] = useState(0);

  const previousImage = () => {
    setCurrentPage((prevPage) => {
      if (prevPage === 0) {
        return project.gallery.length - 1;
      } else {
        return prevPage - 1;
      }
    });
  }

  const nextImage = () => {
    setCurrentPage((prevPage) => {
      if (prevPage === project.gallery.length - 1) {
        return 0;
      } else {
        return prevPage + 1;
      }
    });
  }

  return(
    <div className="project-wrapper">
      <div className="project-gallery" style={{ backgroundColor: `${project.color}` }}>
        <div className="btn-next" onClick={()=> nextImage() } />
        <div className="btn-previous" onClick={()=> previousImage() } />
        {
          project?.gallery?.[currentPage]?.video ? (
            <video playsInline autoPlay muted loop>
                <source src={project?.gallery?.[currentPage]?.video} type="video/mp4" />
            </video>
          ) : (
            <div className="picture" style={{ backgroundImage: `url(${project?.gallery?.[currentPage]?.desktop})`}} />
          )
        }
        { project?.url && <a href={project.url} rel="noreferrer" target="_blank" title={project.url_label} className="url-project">🔗 {project.url_label}</a> }
      </div>
      <div className="project-description">
        <div className="row">
          <h3>{ project?.gallery?.[currentPage]?.title ? project.title.replace(/<\/?[^>]+(>|$)/g, " ") : project.category }</h3>
          <h2 dangerouslySetInnerHTML={{ __html: project?.gallery?.[currentPage]?.title ? project?.gallery?.[currentPage]?.title : project.title }} />
          <span>{ project?.gallery?.[currentPage]?.description ? project?.gallery?.[currentPage]?.description : project.description }</span>
        </div>
        {
          project?.gallery?.[currentPage]?.skills && (
            <div className="row last">
              <h3>Skills</h3>
              <ul className="skills">
                {
                  project?.gallery?.[currentPage]?.skills?.map((skill, key) => {
                    return <li key={key}>{skill}</li>
                  })
                }
              </ul>
            </div>
          )
        }
      </div>
    </div>
  )
}

export default ProjectDetails;