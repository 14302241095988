import React from 'react';
import { useLottie } from "lottie-react";
import LoadingLottieAnimation from "./LoadingLottie.json";

const Loading = ({ loading }) => {
  import('./style.scss');

  const options = {
    animationData: LoadingLottieAnimation,
    loop: true
  };

  const { View } = useLottie(options);

  return(
    <div className="loading-wrapper">
      <div className="loading-box">
        <>{ View }</>
        <span className="loading">{ loading }</span>
      </div>
    </div>
  )
}

export default Loading;