import React, { useEffect, useState } from "react";
import { getCollectionQuery } from "@data/firebase/firestore/getData";

import Home from '@pages/Home';
import About from '@pages/About';
import Projects from '@pages/Projects';
import Clients from '@pages/Clients';
import Contact from '@pages/Contact';
import Loading from '@components/Loading';

function App() {
  const [projects, setProjects] = useState(false);
  useEffect(()=> {
    getCollectionQuery('projects', (response) => {
      console.log(response);
      setProjects(response);
    }, [], [{ field: 'year', direction: 'desc' }]) ;

  }, []);

  return (
    <div className="wrapper">
      {
        projects ? (
          <>
            <Home />
            <About />
            <Projects projects={projects} />
            <Clients />
            <Contact />
          </>
        ) : (<Loading />)
      }
    </div>
  );
}

export default App;